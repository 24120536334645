<template>
  <div style="text-align: left">
    <Card style="height:100%">
      <div class="search-con search-con-top">
        集群<Input clearable placeholder="输入集群 搜索" class="search-input"
                 v-model="queryinfo.cluster"
                 style="width:150px"
                 @on-enter="namechange"
                 @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        名称空间<Input clearable placeholder="输入名称空间 搜索" class="search-input"
                 v-model="queryinfo.namespace"
                 style="width:150px"
                 @on-enter="namechange"
                 @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        资源<Input clearable placeholder="输入资源 搜索" class="search-input"
                   v-model="queryinfo.resources"
                   style="width:150px"
                   @on-enter="namechange"
                   @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        服务名<Input clearable placeholder="输入服务名 搜索" class="search-input"
                 v-model="queryinfo.name"
                 style="width:150px"
                 @on-enter="namechange"
                 @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        镜像名<Input clearable placeholder="输入镜像名 搜索" class="search-input"
                  v-model="queryinfo.image"
                  style="width:150px"
                  @on-enter="namechange"
                  @on-change="namechange"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        操作者<Input clearable placeholder="输入操作者 搜索" class="search-input"
                  v-model="queryinfo.operator"
                  style="width:150px"
                  @on-enter="namechange"
                  @on-change="namechange"/>
      </div>
      <div>
        <Table border ref="selection" :columns="columns" :data="data" ></Table>
      </div>
    </Card>
    <template>
      <Page :current="this.queryinfo.page"
            :total="total"
            :page-size-opts="pagesizeopts"
            @on-change="changepage"
            @on-page-size-change="changepagesize"
            show-total
            show-sizer
            style="text-align: right;" />
    </template>
  </div>
</template>

<script>
import {get} from "@/api/http.js";
import moment from "moment";
export default {
  name: "ImageHistory",
  data() {
    return {
      columns: [
        {type: 'selection', width: 60, align: 'center'},
        {title: '集群', key: 'cluster', width: 200},
        {title: '名称空间', key: 'namespace', width: 200},
        {title: '资源类型', key: 'resources', width: 200},
        {title: '服务名', key: 'name', width: 200},
        {title: '历史镜像', key: 'old_image'},
        {title: '镜像', key: 'image'},
        {title: '操作者', key: 'operator', width: 200},
        {title: '创建时间', key: 'created_at', width: 200,
          render: (h,{row}) =>{
            return h('div',this.time_change(row.created_at))
          }
        },
      ],
      data: [],
      pagesizeopts: [10, 20, 30, 40],
      total: 0,
      configlist: [],
      queryinfo: {
        cluster: "",
        namespace: "",
        resources: "",
        name: "",
        image: "",
        operator: "",
        page: 1,
        pagesize: 10,
      },
    }
  },
  methods: {
    time_change(time_str) {
      return moment(time_str).format('YYYY-MM-DD HH:mm:ss')
    },
    changepage (page) {
      this.queryinfo.page = page
      this.getdata(this.queryinfo)
    },
    changepagesize(pagesize) {
      this.queryinfo.page = 1
      this.queryinfo.pagesize = pagesize
      this.getdata(this.queryinfo)
    },

    namechange () {
      this.queryinfo.cluster = this.queryinfo.cluster.trim()
      this.queryinfo.namespace = this.queryinfo.namespace.trim()
      this.queryinfo.resources = this.queryinfo.resources.trim()
      this.queryinfo.name = this.queryinfo.name.trim()
      this.queryinfo.image = this.queryinfo.image.trim()
      this.queryinfo.operator = this.queryinfo.operator.trim()
      this.queryinfo.page = 1
      this.getdata(this.queryinfo)
    },

    getdata(queryinfo){
      const url = "/k8s/api/v1/images/audit"
      get(url,queryinfo)
          .then((resp)=>{
            this.data = resp["data"];
            this.total = resp.count
          }).catch(e=>{
        return e
      })
    },

  },
  created() {
    this.getdata(this.queryinfo)
  },
}
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}

</style>
